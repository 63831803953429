<template>
  <div class="volunteer">
    <div class="volunteer-continar">
	 <div class="redtitle">
	     <span>加入红十字志愿者</span>
	 </div>
	 <div class="projectintroduct">
	     <a href="https://hszzyz.zhiyuanyun.com/" target="view_window">https://hszzyz.zhiyuanyun.com/</a>
		 <p>红十字志愿者平台</p>
	 </div>
     <Workinfo :worklist="worklist"></Workinfo>
     <!-- <Activityvideo :videolist="videolist" :node="type"></Activityvideo> -->
     <!-- <Ndteam :ndlist="ndlsit"></Ndteam> -->
    </div>
  </div>
</template>

<script>
import Activityvideo from '../Volunteer/components/activityvideo'
import Workinfo from './components/workinfo'
import Ndteam from './components/ndteam'
import apiUrls from '@api'
export default {
  components: {
    Workinfo,
    Activityvideo,
    Ndteam
   },
  name: 'Volunteer',
  data () {
    return {
      videolist: [],
      worklist:[],
      ndlsit: [],
	  type:'zyzfwhszzyz_hdsp'
    }
  },
  created () {
    this.getvideo()
    this.getworklist()
    this.getndlist()
  },
  methods: {
    getvideo () {
      apiUrls.getvolunteer({node: 'zyzfwhszzyz_hdsp'}).then(res=>{
		  console.log(res.results.data)
      this.videolist= res.results.data
    })
    },
    getworklist () {
      apiUrls.getvolunteer({node: 'zyzfwhszzyz_gzxx'}).then(res=>{
      this.worklist= res.results.data
    })
    },
    getndlist () {
      apiUrls.getvolunteer({node: 'zyzfwhszzyz_ndgefd'}).then(res=>{
      this.ndlsit= res.results.data
    })
    }
  }
}
</script>

<style lang="less" scoped>
.volunteer {
  background-color: #f5f5f5;
    .volunteer-continar{
        width: 1200px;
        min-height: 400px;
        padding-bottom: 40px;
        margin: 0 auto;
        background-color: #f5f5f5;
        &::before {
            display: block;
            content: ".";
            clear: both;
            height: 0;
            overflow: hidden;
            visibility: hidden;
        }
    }
	.redtitle  {
	    cursor: pointer;
	    margin-top: 10px;
	    font-size: 23px;
	    color: #333333;
	    font-weight: bold;
	    display: flex;
	    justify-content: space-between;
	    align-items: center;
	    margin-bottom: 16px;
	    span {
	        &:nth-child(1) {
	        display: flex;
	        &::before {
	        content: "";
	        display: block;
	        height: 20px;
	        width: 20px;
	        background: url('../../assets/img/title.png') no-repeat center center;
	       }
	     }
		}
	}
	.projectintroduct {
	    height: 30px;
	    background-color: #ffffff !important;
	    overflow: hidden;
	    padding: 40px 23px 40px 40px;
	    box-shadow: 0px 0px 18px 0px rgba(14,14,14, 0.13);
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 16px;
		a {
			text-decoration: none;
			color: #333333;
		}
		p {
			color: #ABABAB;
		}
	}
}
</style>
